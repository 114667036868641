import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/Layout"
import LayoutContainer from "../components/LayoutContainer"
import { ABOUT_US_ROUTE, CONTACT_US_ROUTE } from "../constants"
import DarkBackgroundSVG from "../components/DarkBackgroundSVG"

function modifyHTMLList(html: string) {
  return html.replace(/<li>/g, '<li><div><span /></div><div>').replace(/<\/li>/g, '</div></li>');
}
const AboutUsSection: React.FC<{
  heading: string
  content: string
  image: any
  alt?: boolean
}> = ({ heading, content, image, alt }) => {
  return (
    <section className={`relative py-12 sm:py-16${alt ? " bg-gray-100" : ""}`}>
      <div className="relative">
        <LayoutContainer>
          <div className="lg:max-w-screen-xl m-auto max-w-screen-md">
            <div
              className={`flex relative z-10 flex-wrap -mx-4${
                alt ? "" : " lg:flex-row-reverse"
              }`}
            >
              <div className="w-full lg:w-6/12 p-4">
                <h1 className="text-5xl font-bold leading-none">
                  {heading}
                </h1>
                <div dangerouslySetInnerHTML={{ __html: modifyHTMLList(content) }} className="page-content"/>
              </div>
              <div className="w-full lg:w-6/12 p-4">
                <div className="shadow-xl">
                  <GatsbyImage fluid={image} />
                </div>
              </div>
            </div>
          </div>
        </LayoutContainer>
      </div>
    </section>
  )
}

export default () => {
  const pageData = useStaticQuery(graphql`
    query {
      content {
        aboutBannerHeader
        aboutBannerMessage
        aboutBannerImage {
          contentImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutBody1Header
        aboutBody1Message
        aboutBody1Image {
          contentImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutBody2Header
        aboutBody2Message
        aboutBody2Image {
          contentImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutBody3Header
        aboutBody3Message
        aboutBody3Image {
          contentImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutBodyFooterMessage
      }
    }
  `)
  const {
    aboutBannerHeader,
    aboutBannerMessage,
    aboutBannerImage,
    aboutBody1Header,
    aboutBody1Message,
    aboutBody1Image,
    aboutBody2Header,
    aboutBody2Message,
    aboutBody2Image,
    aboutBody3Header,
    aboutBody3Message,
    aboutBody3Image,
    aboutBodyFooterMessage,
  } = pageData.content
  const sections = [
    {
      heading: aboutBody1Header,
      content: aboutBody1Message,
      image: aboutBody1Image.contentImage.childImageSharp.fluid,
      alt: false,
    },
    {
      heading: aboutBody2Header,
      content: aboutBody2Message,
      image: aboutBody2Image.contentImage.childImageSharp.fluid,
      alt: true,
    },
    {
      heading: aboutBody3Header,
      content: aboutBody3Message,
      image: aboutBody3Image.contentImage.childImageSharp.fluid,
      alt: false,
    },
  ]
  return (
    <Layout
      pageTitle="About Us"
      pageURL={ABOUT_US_ROUTE}
      pageDescription="We love the accessibility and fun involved with golf carts - we have just as much fun building golf carts as we do driving them! We look forward to meeting the needs of our customers with professionally-crafted, completely street-legal vehicles."
    >
      <header className="text-white relative py-12 bg-teal-800">
        <DarkBackgroundSVG />
        <LayoutContainer>
          <div className="lg:max-w-screen-xl m-auto max-w-screen-md">
            <div className="flex relative z-10 items-center flex-wrap -mx-4">
              <div className="w-full lg:w-6/12 px-4">
                <h6 className="tracking-widest text-teal-200 text-sm mb-3">
                  ABOUT US
                </h6>
                <h1 className="text-5xl sm:text-6xl font-bold leading-none mb-4">
                  {aboutBannerHeader}
                </h1>
                <p>{aboutBannerMessage}</p>
              </div>
              <div className="w-full lg:w-6/12 p-4">
                <div className="shadow-xl">
                  <GatsbyImage
                    fluid={aboutBannerImage.contentImage.childImageSharp.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
        </LayoutContainer>
      </header>
      {sections.map((section, index) => {
        return <AboutUsSection {...section} key={`section-${index}`} />
      })}
      <section className="pb-12">
        <LayoutContainer>
          <div className="text-center max-w-screen-md m-auto">
            <p className="mb-4">{aboutBodyFooterMessage}</p>
            <Link
              to={CONTACT_US_ROUTE}
              className="m-auto block w-full sm:w-48 text-center shadow bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded"
            >
              CONTACT US TODAY
            </Link>
          </div>
        </LayoutContainer>
      </section>
    </Layout>
  )
}
